// core version + navigation, pagination modules:
import Swiper, {
  Autoplay, EffectFade, Navigation, Pagination,
} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

export default function init() {
  const swipers = (document.getElementsByClassName('swiper'));

  if (swipers.length > 0) {
    // configure Swiper to use modules
    Swiper.use([Pagination, EffectFade, Autoplay, Navigation]);
    for (let i = 0; i < swipers.length; i++) {
      if (swipers[i].id) {
        // Configure defualt options used by all swipers
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const options: any = {
          speed: 1000,
          slidesPerView: 1,
        };

        // set different swiper params based on the type of swiper
        if (swipers[i].classList.contains('testimonial-swiper')) {
          options.loop = true;
          options.pagination = {
            el: `.swiper-pagination.${swipers[i].id}`,
            clickable: true,
          };
          options.autoplay = {
            delay: 5000,
          };
          options.effect = 'fade';
          options.fadeEffect = {
            crossFade: true,
          };
        } else if (swipers[i].classList.contains('recent-articles-swiper')) {
          options.slidesPerView = 1.1;
          options.spaceBetween = 16;
          options.breakpoints = {
            768: {
              slidesPerView: 3,
              allowTouchMove: false,
            },
          };
        } else if (swipers[i].classList.contains('promotions-swiper')) {
          options.pagination = {
            el: `.swiper-pagination.${swipers[i].id}`,
            clickable: true,
          };
          options.spaceBetween = 16;
          options.breakpoints = {
            1024: {
              slidesPerView: 2,
            },
          };
          options.loop = true;
        } else if (swipers[i].classList.contains('product-videos-swiper')) {
          options.spaceBetween = 18;
          options.navigation = {
            nextEl: '.swiper-button-next.product-videos-swiper',
            prevEl: '.swiper-button-prev.product-videos-swiper',
          };
          options.breakpoints = {
            1024: {
              slidesPerView: 3,
            },
          };
        } else if (swipers[i].classList.contains('related-products-swiper')) {
          options.spaceBetween = 16;
          options.breakpoints = {
            1024: {
              slidesPerView: 4,
            },
          };
        }

        const swiper = new Swiper((`.swiper.${swipers[i].id}`), options);

        swiper.init();
      }
    }
  }
}
